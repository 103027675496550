import React from 'react';
import './index.css';
import Navbar from './components/navbar';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import Home from './pages/home';
import Rules from './pages/rules';

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/rules' element={<Rules />} />
            </Routes>
        </Router>
    );
}

export default App