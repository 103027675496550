import './home.css';

export default function Home() {
    return (
        <div className='home'>
            <div style={{
                padding: 50,
            }}>
                <div className="h-200 p-5 text-black bg-light rounded-3">
                    <h1>Big Red Battle Of The Bands</h1>
                    <br></br>
                    <a href="https://cglink.me/2ee/s94958" target="_blank" rel="noreferrer">
                        <button className="sub">
                            <h2>LINK TO VOTE</h2>
                        </button>
                    </a>
                </div>
            </div>

        </div >
    );
}
